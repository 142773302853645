import styled, { css } from 'styled-components';

export const sectionStyles = css`
  width: 100vw;
  height: 100vh;
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const wrapperStyles = css`
  max-width: 100%;
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const Logo = styled.div`
  position: relative;
  width: 250px;
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 100%;
    height: 100%;
    animation: scale 5s linear infinite;
    padding: 32px;
  }
  @keyframes scale {
    0% {
      transform: scale(0.8);
    }
    50% {
      transform: scale(1);
    }
    100% {
      transform: scale(0.8);
    }
  }
`;
